<template>
  <div class="statistics">
    <mcb-tool-bar text="Статистика назначений" />

    <el-table
      :data="tableData"
      :default-sort="sortObj(currentSort)"
      empty-text="Нет данных"
      @sort-change="val => currentSort=sortStr(val)"
      style="width: 100%">

      <el-table-column prop="created_at_iso" label="Загружен" sortable="custom">
        <template slot-scope="scope">
          {{ruDateTime(scope.row.created_at_iso)}}
        </template>
      </el-table-column>

      <el-table-column label="Фото">
        <template slot-scope="scope">
          <div v-if="!scope.row.status">
            <router-link :to="{ name: 'statistic_raw', params: { prescriptionId: scope.row.id, mode: 'raw' }, query: $route.query }">Сырое Фото</router-link>
          </div>

          <div v-if="scope.row.status == 1">
            <router-link :to="{ name: 'statistic_fio', params: { prescriptionId: scope.row.id, mode: 'fio' }, query: $route.query }">Фото ФИО</router-link>
          </div>

          <div v-if="scope.row.status > 0">
            <router-link :to="{ name: 'statistic_prescription', params: { prescriptionId: scope.row.id, mode: 'prescription' }, query: $route.query }">Фото назначения</router-link>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="status_text" label="Статус" sortable="custom" />
      <el-table-column prop="reason" label="Причина отказа" />

      <el-table-column prop="drugs" label="Лекарственные ср-ва">
        <template slot-scope="scope">
          <div :key="index1" v-for="(drug, index1) in scope.row.drugs">{{drug}}</div>
        </template>
      </el-table-column>

      <el-table-column prop="diagnoses" label="Диагноз">
        <template slot-scope="scope">
          <div :key="index2" v-for="(diagnose, index2) in scope.row.diagnoses">{{diagnose}}</div>
        </template>
      </el-table-column>

      <el-table-column prop="cashback" label="Начислено" sortable="custom" />

      <el-table-column prop="handwritten" label="Рукописный" sortable="custom" width="140px">
        <template slot-scope="scope">
          <i class="el-icon-check" v-if="scope.row.handwritten" />
        </template>
      </el-table-column>

    </el-table>

    <el-pagination
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      hide-on-single-page
      :total="totalRecords"
      :current-page="currentPage" 
      @current-change="val => currentPage=val" />

    <statistic :prescription-id="prescriptionId" :mode="mode" @cancel="cancelHandle" v-if="!!prescriptionId" />
  </div>
</template>

<script>
import { ruDateTime } from '@/components/utils.js'
import MultiPage from '@/mixins/multi-page.js'

export default {
  props: {
    prescriptionId: {
      type: Number
    },
    mode: {
      type: String
    }
  },

  mixins: [MultiPage],
  
  components: { Statistic: () => import('./Statistic.vue') },

  data () {
    return {
      keyWord: 'statistic',
      ruDateTime: ruDateTime
    }
  }
}
</script>

<style scoped lang="less" >
@import "../style.less";

.statistics {
  a {
    color: #409EFF;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
